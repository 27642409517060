import React, { useCallback, useEffect, useState } from "react";
import ClientsList from "./ClientList";
import ClientInput from "../../../../../../common/element/Input/clientInput";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import Select from "../../../../../../common/element/Select";
import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import Validations from "../../../../../../common/404/Validations";
import Dialog from "../../../../../../common/element/Dialog";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import { GetAllProviderList } from "../../../../../../actions/admin-worker/GetAllProviders";
import { GetAllClientsOfProviderList } from "../../../../../../actions/admin-worker/GetAllProviders";
import { Pagination } from "../../../../../../common/pagination";
// import { GetAllClientsOfProvider } from "../../../../../../actions/admin-provider/clients/GetAllClientsOfProvider";
import { GetAllClientsOfProvider } from "../../../../../../actions/admin-worker/WorkerClientsAssigned";
import Segment from "../../../../../../common/element/Segment";
import { GetProviderAllClientInvites } from "../../../../../../actions/admin-provider/clients/GetProviderAllClientInvites";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import InvitedList from "./invited";
import { el } from "date-fns/locale";
import { GetAllClientsOfWorkerByProvider } from "../../../../../../actions/admin-worker/GetAllClientsOfWorkerByProvider";
const ClientsAssigned = () => {
    const { SpecificProviderData } = useSelector((state) => state);
    let function_assignment_uuid = SpecificProviderData?.provider_fa_uuid;
    const { id } = useParams();
    const dispatch = useDispatch();
    const handleUnauthorizedAccess = useUnauthorizedHandler();
    const [countData, setCountData] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [apiCalls, setApiCalls] = useState(true);
    const [allClientsAssign, setAllClientsAssign] = useState([]);
    const [dialogChoose, setChoose] = useState(false);
    const [dialogSuccess, setDialogSuccess] = useState(false);
    const [singleSegmentValue, setSingleSegmentValue] = useState("all");
    const [invitedData, setInvitedData] = useState([]);
    const [searchProvider, setSearchProvider] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [searchClient, setSearchClient] = useState("");
    const [providerFunUuid, setProviderFunUuid] = useState("");
    const [providerFaUuid, setProviderFaUuid] = useState("");
    const [contactTypeId, setContactTypeId] = useState("");
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedClientOrgIds, setSelectedClientOrgIds] = useState([]);
    const [errorValidation, setErrorValidation] = useState({
        providerNameError: "",
        clientNameError: "",
    });
    const [selectedProvType, setSelectedProvType] = useState(null);
    const [searchOptions, setSearchOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const { workerRedirectSpecificData } = useSelector((state) => state)
    const GetFunctionAll = useSelector((state) => state?.allFunctions?.allFunction);
    const AllContactType = useSelector((state) => state?.AllContactTypes);
    useEffect(() => {
        // Set breadcrumbs when the component mounts
        dispatch(
            setBreadcrumbs([
                {
                    label: "View All Worker",
                    url: "/admin/worker/view-all-worker",
                    home: "/admin/worker/dashboard",
                },
                {
                    label: `${workerRedirectSpecificData?.first_name || ''} ${workerRedirectSpecificData?.last_name || ''}`,
                    url: `/admin/worker/view-all-worker/specific-worker/${id}`,
                },

                {
                    label: "Clients Assigned",
                    url: `/admin/worker/view-all-worker/specific-worker/${id}`,
                },
            ])
        );
    }, []);


    // FilTER DATA SHOW
    const [getAllinvitedData, SetgetAllinvitedData] = useState({
        provider_org_uuid: id,
        worker_uuid:id, 
        page: 1,
        limit: 10,
        sort: "trading_name",
        order: "desc",
        search: "",
    });

    useEffect(() => {
        if (AllContactType) {
            const contactTypeUuid = AllContactType.find(
                (item) => item?.contact_type === "provider primary"
            )?.contact_type_uuid;
            setContactTypeId(contactTypeUuid);
        }
        if (GetFunctionAll) {
            const providerFunId = GetFunctionAll?.find(
                (item) => item?.function_name === "provider"
            )?.function_uuid;
            setProviderFunUuid(providerFunId)
        }
    }, []);
    const handleSearchChange = useCallback(debounce((newInputValue, identifier) => {
        if (identifier === "provider") {
            setSearchProvider(newInputValue);
        }
        if (identifier === "client") {
            setSearchClient(newInputValue);
        }
    }, 500), []);
    const handleSelectChange = (selectedOption, identifier) => {
        if (identifier === "provider") {
            setSelectedProvType(selectedOption);
            setErrorValidation((prevErrors) => ({ ...prevErrors, providerNameError: "", }));
        }
        if (identifier === "client") {
            setSelectedClient(selectedOption);
            const FilterOrgIds = selectedOption?.map((item) => item.organisation_uuid);
            setSelectedClientOrgIds(FilterOrgIds ? FilterOrgIds : [])
            setErrorValidation((prevErrors) => ({ ...prevErrors, clientNameError: "", }));
        }
    }
    const GetProviderListData = useCallback(() => {
        if (searchProvider?.trim().length >= 3) {
            dispatch(GetAllProviderList(searchProvider, providerFunUuid, contactTypeId))
                .then((response) => {
                    if (response?.success) {
                        setProviderFaUuid(response?.data?.[0]?.function_assignment_uuid);
                        // setProviderOrgUuid(response?.data?.[0]?.organisation_uuid);
                        const FilterData = response?.data?.map((item) => {
                            return {
                                label: item?.trading_name,
                                organisation_uuid: item?.organisation_uuid,
                                trading_name: item?.trading_name,
                            };
                        });
                        if (FilterData) {
                            setSearchOptions(FilterData);
                        }
                    } else if (response?.status === 401) {
                        handleUnauthorizedAccess();
                    }
                });
        }
    }, [dispatch, searchProvider])

    useEffect(() => {
        GetProviderListData()
    }, [searchProvider])

    const GetAllClientsOfProviderLists = useCallback(() => {
        if (providerFaUuid && selectedProvType) {
            dispatch(GetAllClientsOfProviderList(providerFaUuid))
                .then((response) => {
                    if (response?.success) {
                        if (response?.data.length > 0) {
                            const filteredData = response?.data.map(client => ({
                                label: client.trading_name,
                                value: client.organisation_uuid,
                                organisation_uuid: client.organisation_uuid,
                                function_assignment_uuid: client.function_assignment_uuid,
                                trading_name: client.trading_name
                            }));
                            setClientOptions(filteredData);
                        }
                    } else if (response?.status === 401) {
                        handleUnauthorizedAccess();
                    }
                });
        }
    }, [dispatch, providerFaUuid, selectedProvType])
    useEffect(() => {
        GetAllClientsOfProviderLists()
    }, [providerFaUuid, selectedProvType])

    const submitAssignClient = () => {
        // e.preventDefault();

        const providerValidation = Validations({ value: selectedProvType?.label, Validations: { required: true } });
        if (!providerValidation.valid) {
            setErrorValidation((prevErrors) => ({ ...prevErrors, providerNameError: providerValidation.error_msg }));
        }
        // Validate client  selectedClient
        const clientValidation = Validations({ value: selectedClientOrgIds?.[0], Validations: { required: true } });
        if (!clientValidation.valid) {
            setErrorValidation((prevErrors) => ({ ...prevErrors, clientNameError: clientValidation.error_msg }));
        }

        if (providerValidation.valid == true && clientValidation?.valid == true) {
      
            // setButtonDisabled(true)
            const payload = {
                trading_name: selectedProvType?.trading_name ? selectedProvType?.trading_name : "",
                workerClientsIds: selectedClientOrgIds?.length > 0 ? selectedClientOrgIds : []
            }
            
            // dispatch(AssignClientToProvider(payload))
            //     .then((response) => {
            //         if (response?.success) {
            //             setButtonDisabled(false);
            //             setDialogSuccess(true);
            //             closeAssignClient();
            //         } else if (response?.status === 401) {
            //             handleUnauthorizedAccess();
            //         }
            //     })
            //     .catch((error) => {
            //         console.error("Failed to assign client to provider:", error);
            //         setButtonDisabled(false);
            //     }).finally(() => {
            //         setButtonDisabled(false);
            //     })
        }

    }

    const openAssignClient = () => {
        setChoose(true);
    }
    const closeAssignClient = () => {
        if (!buttonDisabled) {
            setChoose(false);
            setErrorValidation({
                providerNameError: "",
                clientNameError: "",
            })
            setSelectedClientOrgIds([])
            setSearchOptions([]);
            setSelectedProvType(null);
            setSelectedClient(null);
            setClientOptions([]);
            setProviderFaUuid("")
    
        }
    }
    const closeDialogSuccess = () => {
        setDialogSuccess(false);
    }
    // All ReduxFunction

    const GetAllClientAssign = useCallback(() => {
        dispatch(GetAllClientsOfWorkerByProvider(getAllinvitedData))
            .then((response) => {
                if (response?.success) {
                    const arrayData = response?.data;
                    setCountData(response?.data?.count);
                    setAllClientsAssign(arrayData?.rows);
                    setTotalPages(Math.ceil(response?.data?.count / getAllinvitedData?.limit));
                } else if (response.status === 401) {
                    handleUnauthorizedAccess();
                }
            })
            .catch((error) => {
                console.error("Failed to fetch document types:", error);
            });
    }, [dispatch, getAllinvitedData]);

    useEffect(() => {
        GetAllClientAssign();
    }, [getAllinvitedData]);
    // const GetFunction = useSelector((state) => state);

    const handleSort = (column) => {
        // if (column === getAllinvitedData.sort) {
        SetgetAllinvitedData((prevalue) => {
            return {
                ...prevalue,
                ["sort"]: column,
            };
        });

        SetgetAllinvitedData((prevalue) => {
            return {
                ...prevalue,
                ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
            };
        });
    };
    const handlePageChange = ({ selected }) => {
        SetgetAllinvitedData((prevalue) => {
            return {
                ...prevalue,
                ["page"]: selected + 1,
            };
        });
        setTotalPages(
            Math.ceil(
                countData /
                getAllinvitedData.limit
            )
        );
    };
    const updatePageLimit = (newLimit) => {
        if (apiCalls == true) {
            setApiCalls(false)
        }
        else {

            SetgetAllinvitedData(prevState => ({
                ...prevState,
                limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
                page: 1
            }));
            setTotalPages(Math.ceil(countData / newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10));
        }
    };

    const updatePage = (newPage) => {
        if (apiCalls == true) {
            setApiCalls(false)
        }
        else {

            SetgetAllinvitedData(prevState => ({
                ...prevState,
                page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
            }));
        }

    };
    // Search Data
    const handleSearch = (e) => {
        SetgetAllinvitedData((prevalue) => {
            return {
                ...prevalue,
                [e.target.name]: e.target.value,
            };
        });
    };
    const inputchangehandler = useCallback(debounce(handleSearch), []);
    const getPageNumber = () => {
        setTotalPages(
            Math.ceil(
                countData /
                getAllinvitedData.limit
            )
        );
    };
    useEffect(() => {
        getPageNumber();
    }, [getPageNumber]);

    // Update Main Tabs Function
    const onSingleSelectionSegmentChange = useCallback(
        (val) => {
            if (val?.[0] === undefined ? true : val[0] === "all") {
                // page: 1,
                // limit: 10,
                // sort: "created_date",
                // order: "desc",
                // search: "",});
            } else {
                dispatch(GetProviderAllClientInvites(getAllinvitedData)).then((response) => {
                    if (response.success) {
                        setInvitedData(response?.data?.rows)
                    }
                    else {
                        setInvitedData([])
                    }
                });
                // let type=val[0] === "Internal" ?"internal":"clientContact"
                // setType(type)
                // SetgetAllinvitedData((prevalue) => {
                //   return {
                //     ...prevalue,
                //     type: type,
                //     search: "",
                //     page: 1,
                //     limit: 10,
                //     sort: "created_date",
                //     order: "desc",
                //   };
                // });
            }
            setSingleSegmentValue(val[0]);
        },
        [setSingleSegmentValue]
    );
    console.log("workerRedirectSpecificData::",workerRedirectSpecificData)
    return (
        <React.Fragment>
            <div className="flex justify-between mb-5 mt-7">
                <div className="flex gap-2 validation-filter">
                    {/* <Segment
            value={singleSegmentValue}
            onChange={(val) => onSingleSelectionSegmentChange(val)}
          >
            <Segment.Item value="all" className="h-9">
              <span>View all</span>
            </Segment.Item>
            <Segment.Item value="Invited" className="h-9">
              <span>Invited</span>
            </Segment.Item>
          </Segment> */}
                    <div className="w-80 h-10">
                        <div className="input-wrapper">
                            <div className="input-suffix-start left-3.5">
                                <img
                                    src="/assets/icons-svg/search-lg.svg"
                                    alt=""
                                    className="h-5"
                                />
                            </div>
                            <ClientInput
                                type="text"
                                className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Search"
                                inputchangehandler={inputchangehandler}
                                name={"search"}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 reset-dropdown">
                    <BtnPrimary
                        onClick={() => openAssignClient()}
                        variant="solid"
                        className="w-auto h-9 flex items-center justify-center text-12 px-3.5"
                        type="button"
                    >
                        Assign client
                        <span className="ml-3">
                            <img src="/assets/icons-svg/plus.svg" alt="" className="h-5" />
                        </span>
                    </BtnPrimary>
                </div>
            </div>
            <ClientsList
                GetAllClientAssign={GetAllClientAssign}
                allClientsAssign={allClientsAssign}
                handleSort={handleSort}
                getAllinvitedData={getAllinvitedData}
            />
            {/* {singleSegmentValue == "all" ? (
        <ClientsList
          handleSort={handleSort}
          getAllinvitedData={getAllinvitedData}
        />
      ) : (
        <InvitedList handleSort={handleSort}
        getAllinvitedData={getAllinvitedData}
        invitedData={invitedData}
        />
      )} */}

            <div className="flex justify-between mt-3">
                <Pagination
                    totalCount={countData || 0}
                    pageNumber={getAllinvitedData.page}
                    displayPage={allClientsAssign?.length || 0}
                    // UpdatePageLimit={SetgetAllinvitedData}
                    // allfilters={getAllinvitedData}
                    UpdatePageLimit={updatePageLimit}
                    UpdatePage={updatePage}
                />
                {countData != 0 && (
                    <ReactPaginate
                        className="flex justify-content-end p-2 align-items-center pagination-wrap"
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        previousLinkClassName={"prev-link"}
                        nextLinkClassName={"page-link"}
                        breakLinkClassName={"prev-link"}
                        previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
                        nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
                        pageLinkClassName={"px-4 rounded-md"}
                        activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
                        disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
                        renderOnZeroPageCount={null}
                        forcePage={getAllinvitedData?.page - 1}
                    />
                )}
            </div>
            <Dialog
                isOpen={dialogChoose}
                onClose={closeAssignClient}
                onRequestClose={closeAssignClient}
                bodyOpenClassName="overflow-hidden"
                width={720}
            >
                <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
                    <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 21.5L22 18.5M22 18.5L19 15.5M22 18.5H16M12 16H7.5C6.10444 16 5.40665 16 4.83886 16.1722C3.56045 16.56 2.56004 17.5605 2.17224 18.8389C2 19.4067 2 20.1044 2 21.5M14.5 8C14.5 10.4853 12.4853 12.5 10 12.5C7.51472 12.5 5.5 10.4853 5.5 8C5.5 5.51472 7.51472 3.5 10 3.5C12.4853 3.5 14.5 5.51472 14.5 8Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <div>
                        <h5 className="mb-0 text-gray-900 text-base font-semibold">
                            Assign client
                        </h5>
                    </div>
                </div>

                <div className="px-6">
                    <div className="mb-5">
                        <label className="font-medium text-gray-700 w-1/4 mb-1 block">
                            Provider*
                        </label>
                        <div className="w-full hide-select-elt">
                            <Select
                                className="w-full h-9 select-options"
                                placeholder="Select a provider"
                                options={searchOptions}
                                value={selectedProvType}
                                onChange={(e) => handleSelectChange(e, "provider")}
                                handleSearchChange={(e) => handleSearchChange(e, "provider")}
                            ></Select>
                            {errorValidation?.providerNameError && (
                                <div className="text-red-500 text-sm mt-1 ">
                                    {errorValidation?.providerNameError}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-5">
                        <label className="font-medium text-gray-700 mb-1 block">
                            Client*
                        </label>
                        <div className="w-full hide-select-elt">
                            <Select
                                className="w-full h-9 select-options"
                                placeholder="Select a client"
                                options={clientOptions}
                                value={selectedClient}
                                isMulti={true}
                                onChange={(e) => handleSelectChange(e, "client")}
                                handleSearchChange={(e) => handleSearchChange(e, "client")}
                            ></Select>
                            {errorValidation?.clientNameError && (
                                <div className="text-red-500 text-sm mt-1 ">
                                    {errorValidation?.clientNameError}
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                <div className="flex dailog-footer ">
                    <BtnLight disabled={buttonDisabled} className="mr-1.5" variant="plain" onClick={closeAssignClient}>
                        Cancel
                    </BtnLight>
                    <BtnPrimary
                        disabled={buttonDisabled}
                        onClick={() => {
                            submitAssignClient()
                        }}
                        className="ml-1.5">
                        Assign
                        {buttonDisabled ? <ThreeDotLoader /> : "Assign"}
                    </BtnPrimary>
                </div>
            </Dialog>
            <Dialog
                isOpen={dialogSuccess}
                onClose={closeDialogSuccess}
                onRequestClose={closeDialogSuccess}
                bodyOpenClassName="overflow-hidden"
                className="customAlert"
            >
                <div className="px-6 pt-6">
                    <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
                        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.390625" y="0.586914" width="48" height="48" rx="24" fill="#D1FADF" />
                            <path d="M24.3906 28.0869H19.8906C18.4951 28.0869 17.7973 28.0869 17.2295 28.2592C15.9511 28.647 14.9507 29.6474 14.5629 30.9258C14.3906 31.4936 14.3906 32.1914 14.3906 33.5869M31.3906 33.5869V27.5869M28.3906 30.5869H34.3906M26.8906 20.0869C26.8906 22.5722 24.8759 24.5869 22.3906 24.5869C19.9053 24.5869 17.8906 22.5722 17.8906 20.0869C17.8906 17.6016 19.9053 15.5869 22.3906 15.5869C24.8759 15.5869 26.8906 17.6016 26.8906 20.0869Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </span>
                    <p className="text-base text-gray-900 font-semibold text-center mt-4">
                        Client assigned!
                    </p>
                </div>
                <div className="mt-8 flex justify-between pb-6 px-6">
                    <BtnPrimary onClick={closeDialogSuccess}>Close</BtnPrimary>
                </div>
            </Dialog>
        </React.Fragment>
    );
};
export default ClientsAssigned;
