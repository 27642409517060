import AppRoutes from "./routes/index";
import ProvideAuth from "./context/Auth";
import Notification, { notify } from "react-notify-toast";
// import Notifications from "./templates/adminPortal/Socket";
import { useUserback } from "@userback/react";
import { useEffect, useState } from "react";
import Gleap from "gleap";

// Main App
function App() {
  const [count, setCount] = useState(0);
  const { show, hide, open } = useUserback();
    
  useEffect(() => {
    // Gleap.initialize("EKa1LYppi62qK0R9TAC3nIRxMWgMzOSj");
  }, []);
  
  return (
    <div id="scroller" className="">
      {/* <div className="App">
        <div>
        </div>
        
        <div className="card">
          <button onClick={() => setCount((count) => count + 1)}>
            count is {count}
          </button>
          <hr />
          <button type="button" onClick={() => open("bug")}>
            Open Bugs
          </button>
          <button type="button" onClick={() => open("general", "screenshot")}>
            Screenshot me!
          </button>
          <hr />
          <button type="button" onClick={hide}>
            Hide
          </button>
          <button type="button" onClick={show}>
            Show
          </button>
        </div>
        <p className="read-the-docs">
          Click on the Vite and React logos to learn more
        </p>
      </div> */}
      {/* <Notifications/> */}
      <ProvideAuth>
        {/* <BrowserRouter> */}
        <Notification />
        <AppRoutes />
        {/* </BrowserRouter> */}
      </ProvideAuth>
    </div>
  );
}

export default App;
