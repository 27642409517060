import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Tabs from "../../../../common/element/Tabs";
import Overview from "./SpecificClientTabs/Overview";
import Contacts from "./SpecificClientTabs/Contacts";
import ClientName from "./SpecificClient/ClientName";
import Providers from "./SpecificClientTabs/Providers";
import Sites from "./SpecificClientTabs/Sites";
import ValidationCriteria from "./SpecificClientTabs/ValidationCriteria";
import ContactsList from "./SpecificClientTabs/Contacts/ContactsList";
import CompanyInduction from "./SpecificClientTabs/CompanyInduction";
import * as userActions from "../../../../actions/index";
import { useParams } from "react-router-dom";
import TotalContractors from "./SpecificClientTabs/Overview/TotalContractors";
import { DashboardClientCompanyInductions } from "../../../../actions/Admin-Client/AllSiteApi";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { specificClientData } from "../../../../redux/classes/Admin-Client/ClientProfileDataSlice";
const { TabNav, TabList, TabContent } = Tabs;

// {"userName":{"individual_uuid":"0733a7b5-9370-4852-9846-acee9187edbd","user_uuid":"f5dc3027-7186-4e01-86fc-709baacdfa5c","title":null,"first_name":"test","last_name":"test","email":"clientUser@gmail.com","phone":null,"phone_optional":null,"state_id":null,"country_id":null,"avatar":null,"is_conserve_team":false,"occupation":null,"user_data":{"user_uuid":"f5dc3027-7186-4e01-86fc-709baacdfa5c","individual_uuid":"0733a7b5-9370-4852-9846-acee9187edbd","password":null,"user_otp":"$2b$10$wCdmCzA267c2uWQbJckUOeibKK4g3AzP1resocU4RidiZKVc433aK","otp_created_date":"2024-05-29T08:01:45.026Z","two_factor_auth":false,"last_login":"2024-06-03T05:30:12.304Z","is_worker":false,"is_default_login_as_worker":false}},"functionUuid":"d88d1656-0cbc-498b-895d-f0abfa65a4c9","function_assignment_uuid":"fd91be60-d246-413f-825e-aba18dbb3f6a","trading_name":"Dikonia","organisation_uuid":"edaa8a03-6ffb-474b-8214-a49ca3b4fd71"}

const SpecificClients = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_uuid;
  let organisation_uuid;
  if (loginData) {
    role_assignment_uuid = loginData?.data?.role_assignment_uuid;
  }
  const [contactTypeId, setContactTypeId] = useState("");
  const [clientData, setClientData] = useState(null);
  const [clientDataName, setClientDataName] = useState([])
  const [defaultTab, setDefaultTab] = useState("tab1");
  const [contactFunAssId, setContactFunAssId] = useState("")
  const AllContactType = useSelector((state) => state?.AllContactTypes);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "View All Clients", url: "/admin/client/view-all-clients", home: "/admin/client/dashboard" },
        {
          label: clientData?.trading_name,
          url: `/admin/client/view-all-clients/specific-client/${id}`,
        },
      ])
    );
  }, [clientData]);

  useEffect(() => {
    if (AllContactType) {
      const contactTypeUuid = AllContactType.find(
        (item) => item?.contact_type === "client primary"
      )?.contact_type_uuid;
      setContactTypeId(contactTypeUuid);
    }
  }, []);
  const GetClientOverviewProfileById = useCallback(async () => {
    if (id && contactTypeId) {
      dispatch(
        userActions?.GetClientOverviewProfileById(id, contactTypeId)
      ).then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data;
          setClientData(arrayData);
          dispatch(specificClientData(arrayData));
          setContactFunAssId(arrayData?.function_assignment_uuid)
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      });
    }
    // eslint-disable-next-line
  }, [dispatch, id, contactTypeId]);

  useEffect(() => {
    GetClientOverviewProfileById();
  }, [id, contactTypeId])


  


  //call permission api 
  // const GetSpecificUserRole = useCallback(async () => {
  //   if (role_assignment_uuid) {
  //     dispatch(userActions.GetSpecificUserRole(role_assignment_uuid)).then(
  //       (response) => {
  //         if (response?.success === true) { }
  //         else if (response.status === 401) {
  //           handleUnauthorizedAccess()
  //         }
  //       }
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [dispatch]);
  // useEffect(() => {
  //   GetSpecificUserRole()
  // }, [])
  const handleTabClick = (tabValue) => {
    setDefaultTab(tabValue);
  };
  return (
    <React.Fragment>
      <ClientName clientData={clientData} id={id} />
      <Tabs value={defaultTab} variant="pill">
        <div className=" bg-gray-25 border border-gray-100 p-1 rounded-lg mb-5">
          <TabList>
            <TabNav value="tab1" onClick={() => handleTabClick('tab1')}>Overview</TabNav>
            <TabNav value="tab2" onClick={() => handleTabClick('tab2')}>Providers</TabNav>
            <TabNav value="tab3" onClick={() => handleTabClick('tab3')}>Compliance Checklist</TabNav>
            <TabNav value="tab4" onClick={() => handleTabClick('tab4')}>Company Induction</TabNav>
            <TabNav value="tab5" onClick={() => handleTabClick('tab5')}>Sites</TabNav>
            <TabNav value="tab6" onClick={() => handleTabClick('tab6')}>Invoices</TabNav>
            <TabNav value="tab7" onClick={() => handleTabClick('tab7')}>Reports</TabNav>
            <TabNav value="tab8" onClick={() => handleTabClick('tab8')}>Contacts</TabNav>
          </TabList>
        </div>
        <div className="">
          <TabContent value="tab1">
            <Overview clientData={clientData}
              // clientDataName={clientDataName}
              setDefaultTab={setDefaultTab}
              GetClientOverviewProfileById={GetClientOverviewProfileById}
            />
          </TabContent>
          <TabContent value="tab2">
            <Providers
              clientData={clientData}
              tradingName={clientData?.trading_name}
            />
          </TabContent>
          <TabContent value="tab3">
            <ValidationCriteria clientData={clientData} />
          </TabContent>
          <TabContent value="tab4">
            <CompanyInduction clientData={clientData} />
          </TabContent>
          <TabContent value="tab5">
            <Sites clientData={clientData} />
          </TabContent>
          <TabContent value="tab8">
            <Contacts
              clientData={clientData}
              organisation_uuid={clientData?.organisation_uuid}
              contactFunAssId={contactFunAssId}
            />
          </TabContent>
        </div>
      </Tabs>

    </React.Fragment>
  );
};

export default SpecificClients;
