import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// Layouts
import AdminLayout from "../templates/adminPortal";
import ClientLayout from "../templates/clientPortal";
import ContractorLayout from "../templates/providerPortal";
// Protected Route
import ProctedRoute from "./proctedRoute";
// Auth page
import Login from "../templates/auth/Login/Login";
import TwoFA from "../templates/auth/Login/2FA";
import PasswordReset from "../templates/auth/Login/PasswordReset";
import Authentication from "../templates/auth/Login";
import ForgotPassword from "../templates/auth/Login/ForgetPassword";
import CheckEmail from "../templates/auth/Login/CheckEmail";
import SetNewPassword from "../templates/auth/Login/SetNewPassword";
// Worker password Creation
import WorkerPasswordCreation from "../templates/workerPortal/pages/PasswordCreation";
// Admin-portal/admin/pages
import { AdminPortalAdminRoutes } from "../templates/adminPortal/routes/admin";
// import AdminPortalAdminRoutes from "../templates/adminPortal/routes/admin.js"
import Users from "../templates/adminPortal/pages/Admin/Users";
import CreatePassword from "../templates/adminPortal/pages/Admin/Users/CreatePassword";
//Admin-portal/CLient/pages
import { AdminPortalClientRoutes } from "../templates/adminPortal/routes/client";
//Admin-portal/provider/pages
import { AdminPortalContractorRoutes } from "../templates/adminPortal/routes/provider";
//Admin-portal/worker/pages
import { WorkerPortalRoutes } from "../templates/workerPortal/routes/worker";

//Provider-portal
import { ProviderPortalRoutes } from "../templates/providerPortal/routes/provider";

import WorkerLayout from "../templates/workerPortal";
import { AdminPortalWorkerRoutes } from "../templates/adminPortal/routes/worker.js";
import ClientUserPassword from "../templates/adminPortal/pages/Client/Dialog/Clients/ClientSettings/ClientUserPassword";
import ContactorUserPassword from "../templates/adminPortal/pages/Provider/Dialog/Clients/ClientSettings/ContactorUserPassword.js";
// InvitationRejected
import InvitationRejected from "../templates/providerPortal/pages/InvitationRejected";
import InvitationFeedback from "../templates/providerPortal/pages/ThankYou";

// expire link
import StepFive from "../templates/auth/Registration/StepFive";
// Client-Portal-Routes
import { ClientPortalRoutes } from "../templates/clientPortal/routes/client";
import ResetPassword from "../templates/adminPortal/pages/Admin/Users/ResetPassword";
import { useSelector } from "react-redux";
// Provider Registration
import WelcomeToConserve from "../templates/providerPortal/pages/ProviderPasswordCreation";
import ProviderPasswordCreation from "../templates/providerPortal/pages/ProviderPasswordCreation";
import Welcome from "../templates/providerPortal/pages/Welcome";
import WelcomeToConserveDoc from "../templates/providerPortal/pages/WelcomeToConserveDoc";
import SignUp from "../templates/auth/Registration/ContractorRegistration/SignUp";
import InboxVerification from "../templates/auth/Registration/ContractorRegistration/InboxVerification";
import PasswordCreation from "../templates/auth/Registration/ContractorRegistration/PasswordCreation";
import InboxVerificationExpire from "../templates/auth/Registration/ContractorRegistration/EmailConfirmation";
import ContractorRegistrationClient from "../templates/auth/Registration/ContractorRegistration/contractorRegistrationClient";
import ContractorRegistrationSelf from "../templates/auth/Registration/ContractorRegistration/contractorRegistrationSelf";
import { SpecificWorkerRoutes } from "../templates/providerPortal/routes/worker";
let getRolle;
let checkRoute = true;
function Routing() {
  if (checkRoute) {
    if (
      window?.location?.href
        ?.split("?")?.[1]
        ?.split("=")
        ?.includes("provider_doc_appr_uuid")
    ) {
      localStorage.setItem(
        "DocUrl",
        `/client/provider-doc-approval${window.location.search}`
      );
    }
    checkRoute = false;
  }
  let getdata;
  const { allFunctions } = useSelector((state) => state);
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const checkAuth = JSON.parse(localStorage.getItem("checkAuth"));
  if (loginUserData && loginUserData.userName.is_conserve_team) {
    getRolle = "conserve-team";
  } else if (checkAuth?.checkAuth == "worker") {
    getRolle = "worker";
  } else {
    if (
      loginUserData &&
      loginUserData != null &&
      loginUserData.functionUuid !== null &&
      loginUserData.functionUuid
    ) {
      getdata =
        allFunctions.allFunction &&
        allFunctions.allFunction.filter(
          (item) => loginUserData.functionUuid == item.function_uuid
        );
      getRolle = getdata[0]?.function_name;
    }
  }

  return (
    // Routing for All Rolle
    <Routes>
      {/* Auth Routing */}
      <Route path="/" element={<Authentication />} />
      <Route
        path="/sign-up/inbox-verification"
        element={<InboxVerification />}
      />
      <Route
        path="/email-verification/expired"
        element={<InboxVerificationExpire />}
      />
      {/* Provider Registration Routes */}
      <Route
        path="/provider-invite/:invite_provider_uuid/provider-password-creation"
        element={<ProviderPasswordCreation />}
      />
      <Route
        path="/provider-invite/:invite_provider_uuid/provider-registration"
        element={<ContractorRegistrationClient />}
      />
      <Route
        path="/verification-email/:email/:token"
        element={<PasswordCreation />}
      />
      <Route
        path="/provider-registration"
        element={<ContractorRegistrationClient />}
      />

      <Route
        path="/provider-registration-yourself"
        element={<ContractorRegistrationSelf />}
      />

      <Route path="/check-email" element={<CheckEmail />} />
      <Route path="/set-new-password" element={<SetNewPassword />} />
      <Route path="admin/user-invite" element={<CreatePassword />} />
      <Route path="admin/reset-password" element={<ResetPassword />} />
      <Route path="/client-user-invite" element={<ClientUserPassword />} />
      <Route path="/provider-user-invite" element={<ContactorUserPassword />} />
      {/* Worker Password Creation */}
      <Route
        path="admin/provider/invite-worker"
        element={<WorkerPasswordCreation />}
      />
      {/* Provider Rejection */}
      <Route path="provider-reject-invite" element={<InvitationRejected />} />
      <Route path="provider-invite" element={<WelcomeToConserveDoc />} />
      {/* Provider Rejection */}
      <Route path="invitationFeedback" element={<InvitationFeedback />} />
      {/* Expire link */}
      <Route path="/expire-link" element={<StepFive />} />
      {/* Protected route */}
      <Route element={<ProctedRoute />}>
        {/* Public Account */}
        <Route path="/2fa-verify" element={<TwoFA />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />

        {getRolle === "conserve-team" && (
          // Admin Portal
          <Route path={"admin"} element={<AdminLayout />}>
            <Route path={"*"} element={<AdminPortalAdminRoutes />} />
            <Route path={"client/*"} element={<AdminPortalClientRoutes />} />
            <Route
              path={"provider/*"}
              element={<AdminPortalContractorRoutes />}
            />
            <Route path={"worker/*"} element={<AdminPortalWorkerRoutes />} />
          </Route>
        )}
        {getRolle === "client" && (
          // Client Portal
          <Route path={"client"} element={<ClientLayout />}>
            <Route path="*" element={<ClientPortalRoutes />} />
          </Route>
        )}
        {getRolle === "provider" && (
          // Provider Portal
          <Route path={"provider"} element={<ContractorLayout />}>
            <Route path="*" element={<ProviderPortalRoutes />} />
            <Route path={"worker/*"} element={<SpecificWorkerRoutes />} />
          </Route>
          
        )}
        {getRolle === "worker" && (
          // Worker Portal
          <Route path={"worker"} element={<WorkerLayout />}>
            <Route path="*" element={<WorkerPortalRoutes />} />
          </Route>
        )}
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
export default Routing;
