import React, {useEffect} from "react"
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../../../common/element/Table";
import Button from "../../../../../../common/element/Buttons";
import Switcher from "../../../../../../common/element/Switcher";
import {GetClientUserPermByIdForClient} from '../../../../../../actions/client-portal/AllUsersApi';
import { useDispatch } from "react-redux";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";

const Notifaction =(props)=>{
    const dispatch = useDispatch()
    const handleUnauthorizedAccess = useUnauthorizedHandler()
const { Tr, Th, Td, THead, TBody } = Table

let function_assignment_uuid;
let user_uuid;

  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    user_uuid =  storedData?.userName?.user_uuid;
    function_assignment_uuid = storedData.function_assignment_uuid;
  }
  // api call for permissions
//   useEffect(() =>{
//     const apiData = {
//       user_uuid: user_uuid,
//       function_assignment_uuid: function_assignment_uuid
//     }
//     dispatch(GetClientUserPermByIdForClient(apiData)).then(
//       (response) => {
//          if (response.success === true) {
//             // const arrayData = response.data;  
//             localStorage.setItem("permission_data", JSON.stringify(response.data))
//           } else if (response.status === 401) {
//             handleUnauthorizedAccess()
//           }
//          }
//     );
//   },[])

const onSwitcherToggle = (val, e) => {
}
return(
<React.Fragment>


   <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
    <div> 
        <h5 className="text-base text-gray-900 font-semibold mb-1">Notifications</h5>
        <p className="text-gray-600">Customise your notification preferences to stay informed and in control of your alerts and updates.</p>
    </div>

        <div className="flex justify-end items-center">
                    <BtnLight disabled={props.settingsPermission} className="w-auto px-4">Cancel</BtnLight>
                    <SmallButton disabled={props.settingsPermission} className="w-auto px-4 ml-3">Save</SmallButton>
                </div>
    
    </div>
 
    <Table className="notification-table">
                <THead>
                    <Tr>
                        <Th>Notify me when...</Th>
                        <Th>Email <br/><Button className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700">Turn on all email</Button></Th>
                        <Th>Desktop <br/><Button className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700">Turn on all email</Button></Th>
                        <Th>SMS <br/> <Button className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700">Turn on all email</Button></Th>
                    </Tr>
                </THead>
                <TBody>
                    <Tr>
                        <Td>
                            <span className="block font-semibold text-gray-700">2-step verification </span>
                            <span className="text-gray-600 text-xs">Add an additional layer of security to your account during login.</span>
                        </Td>
                        <Td>  
                            <Switcher disabled={props.settingsPermission} onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Email</span>
                        </Td>
                        <Td>  
                            <Switcher disabled={props.settingsPermission} onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Desktop</span>
                        </Td>
                        <Td>  
                            <Switcher disabled={props.settingsPermission} onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">SMS</span>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            <span className="block font-semibold text-gray-700">Auctor at habitant euismod gravida in ipsum. Faucibus. </span>
                            <span className="text-gray-600 text-xs">Lectus vitae vulputate in in. Fames in interdum accumsan neque.</span>
                        </Td>
                        <Td>  
                            <Switcher disabled={props.settingsPermission} onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Email</span>
                        </Td>
                        <Td>  
                            <Switcher disabled={props.settingsPermission} onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Desktop</span>
                        </Td>
                        <Td>  
                            <Switcher disabled={props.settingsPermission} onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">SMS</span>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            <span className="block font-semibold text-gray-700">Auctor at habitant euismod gravida in ipsum. Faucibus. </span>
                            <span className="text-gray-600 text-xs">Lectus vitae vulputate in in. Fames in interdum accumsan neque.</span>
                        </Td>
                        <Td>  
                            <Switcher disabled={props.settingsPermission} onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Email</span>
                        </Td>
                        <Td>  
                            <Switcher disabled={props.settingsPermission} onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Desktop</span>
                        </Td>
                        <Td>  
                            <Switcher disabled={props.settingsPermission} onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">SMS</span>
                        </Td>
                    </Tr>

                </TBody>
            </Table>


</React.Fragment>
)
}
export default Notifaction