import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AxiosConfig } from "./config/axios.config";
import { Provider } from "react-redux";
import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import AuthReducer from "./reducers/Auth";
import AllFunctionReducer from "./reducers/AllFunctions";
// import CompanyInductionModule from './reducers/CompanyInductionModule';
import AdminReducer from "./reducers/Admin";
import ClientReducer from "./reducers/Client";
import functionAssignmentUuidReducer from "./reducers/FunctionId";
import AllRoleReducer from "./reducers/AllRole";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSlice from "./redux/classes/createSlice";
import clientCsv from "./redux/classes/client-portal/clientCsvSlice";
import getAllDoctype from "./redux/classes/client-portal/getAllDoctype";
import getAllContractorDoctype from "./redux/classes/provider-portal/getAllContractorDoctype";
import getHighRiskSlice from "./redux/classes/client-portal/getHighRiskSlice";
import getAllComplianceSlice from "./redux/classes/client-portal/getAllComplianceSlice";
import slice from "./redux/classes/client-portal/slice";
import inductionDetailSlic from "./redux/classes/client-portal/inductionDetailsForm";
import inductionModuleDataSlice from "./redux/classes/client-portal/inductionModuleDataSlice";

import breadcrumbsSlice from "./redux/classes/breadcrumbsSlice";
import editInductionSlice from "./redux/classes/client-portal/EeditInduction";

import siteInductionDetailSlice from "./redux/classes/Admin-Client/SiteInductionDetailForm";
// import SiteinductionModuleDataSlice from './redux/classes/Admin-Client/SiteInductionModuleSlice';
import SiteInductionModuleDataSlice from "./redux/classes/Admin-Client/SiteInductionModuleSlice";
import editSiteInductionSlice from "./redux/classes/Admin-Client/EditSiteInductionSlice";

// import GetAllDocumentsOfSpecificContractorSlice from './redux/classes/AdminContractor/GetAllDocumentsOfSpecificContractorSlice';
import GetAllDocumentsOfProvider from "./redux/classes/AdminContractor/GetAllDocumentsOfSpecificContractorSlice";
import GetAllDocumentsOfContractor from "./redux/classes/provider-portal/GetAllDocumentsOfContractorSlice";

import GetAllDocumentsOfSpecificWorker from "./redux/classes/admin-worker/GetAllDocumentsOfSpecificWorkerSlice";
import GetAllProvidersOfWorker from "./redux/classes/admin-worker/GetAllContractorsOfSpecificWorkerslice";

import deletedocument from "./redux/classes/client-portal/deletedocument";
// import CreateCompanyInductionForClientSlice from './redux/classes/client-portal/CreateCompanyInductionForClientSlice';
import GetProviderWorkersInvite from "./redux/classes/AdminContractor/GetSpecificContractorWorkerInvitesSlice";

import GetAdminSpecificContractor from "./redux/classes/AdminContractor/GetAdminSpecificContractorSlice";

import GetAdminSpecificWorker from "./redux/classes/admin-worker/GetAdminSpecificWorkerSlice";
import GetDocumentHistoryById from "./redux/classes/AdminContractor/GetDocumentHistoryByIdSlice";
import GetContractorDocumentHistoryById from "./redux/classes/provider-portal/GetDocumentHistoryByIdSlice";
import GetContractorDoc from "./redux/classes/provider-portal/GetContractorDocSlice";

import inviteWorkerCsvView from "./redux/classes/AdminContractor/inviteContractorWorkerCsvViewSlice";

import inviteProviderCsvView from "./redux/classes/AdminContractor/inviteProviderCsvViewSlice";

import GetAllContactsOfProvider from "./redux/classes/AdminContractor/GetAllContactsOfContractorSlice";

import GetAllClientsOfAdminProvider from "./redux/classes/AdminContractor/GetAdminproviderClientSlice";

import GetAllContactsOfContractorPortal from "./redux/classes/provider-portal/GetAllContactsOfContractorPortalSlice";

import updateDefaultTabeSlice from "./redux/classes/client-portal/updateDefaultTabeSlice";
import updateInductionSlice from "./redux/classes/client-portal/updateInductionSlice";
import refetchAllInductions from "./redux/classes/client-portal/refetchAllInductions";
import closeDocument from "./redux/classes/client-portal/closeDocument";
import closeDocumentSite from "./redux/classes/Admin-Client/closeDocumentSite";
import deleteDocumentSite from "./redux/classes/Admin-Client/deleteDocumentSite";
import refetchAllInductionsSite from "./redux/classes/Admin-Client/refetchAllInductionsSite";
import updatInductionStatusSite from "./redux/classes/Admin-Client/updatInductionStatusSite";
import specificClientDetails from "./redux/classes/Admin-Client/specificClientDatashow";
import updateAvtar from "./redux/classes/common/updateAvtar";
import updateRoute from "./redux/classes/common/updateRoute";
import getAllworkerDocType from "./redux/classes/admin-worker/getAllworkerDocType";
import GetWorkerDocumentHistoryById from "./redux/classes/admin-worker/GetWorkerDocumentHistoryById";
import GetWorkerSiteLogsSlice from "./redux/classes/admin-worker/GetWorkerSiteLogsSlice";
import GetSiteAccessLogsSlice from "./redux/classes/client-portal/GetSiteAccessLogsSlice";
import permissionsReducer from "./redux/classes/client-portal/permissionsSlice";
import GetInducteesSlice from "./redux/classes/Admin-Client/GetClientInducteesSlice";
import GetClientPortalInducteesSlice from "./redux/classes/client-portal/GetClientPortalInducteesSlice";
import GetWorkerportalSiteLogs from "./redux/classes/worker-portal/GetWorkerSiteLogsSlice";
import permissionsProvierPortal from "./redux/classes/provider-portal/permissionsProviderPortal";
import GetAllContractorsOfWorker from "./redux/classes/worker-portal/GetAllContractorsOfWorkerslice";
import ProviderRegisterData from "./redux/classes/common/ProviderRegisterData";
import RegistrationValidation from "./redux/classes/common/RegistrationValidation";
import ProviderContactData from "./redux/classes/provider-portal/ProviderContactData";
import SignUp from "./redux/classes/provider-portal/SignUp";
// import GetAllContractorsOfWorkers from './redux/classes/worker-portal/GetAllContractorsOfWorkerslice';
import checkExistUser from "./redux/classes/provider-portal/checkExistUser";
import RedirectWorkerSpecificData from "./redux/classes/admin-worker/RedirectWorkerSlice";
import specificWorker from "./redux/classes/provider-portal/specificWorker";
import SpecificClientProfileData from "./redux/classes/Admin-Client/ClientProfileDataSlice"
import GetSpecificWorkerIndSlice from "./redux/classes/provider-portal/GetSpecificWorkerIndSlice";
import ProviderWorkerSpecificData from "./redux/classes/provider-portal/ProviderWorkerSpecificData";
import { UserbackProvider } from "@userback/react";
import axios from "axios";
import SpecificProviderData from "./redux/classes/AdminContractor/SpecificProvider"
// import GleapLoader from "./GleapLoader";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  permissions: permissionsReducer,
  workerRedirectSpecificData: RedirectWorkerSpecificData,
  ClientSpecificProfileData: SpecificClientProfileData,
  permissionsProvider: permissionsProvierPortal,
  AdminSpecificContractorData: GetAllDocumentsOfProvider,
  ContractorDocData: GetAllDocumentsOfContractor,
  AdminSpecificWorkerSiteLogsData: GetWorkerSiteLogsSlice,
  SiteAccessLogsData: GetSiteAccessLogsSlice,
  AdminClientInducteesData: GetInducteesSlice,
  ClientPortalInducteesData: GetClientPortalInducteesSlice,
  GetWorkerportalSiteLogsData: GetWorkerportalSiteLogs,
  AdminSpecificWorkerData: GetAllDocumentsOfSpecificWorker,
  WorkerContractorsData: GetAllContractorsOfWorker,
  AdminSpecificWorkerContractorsData: GetAllProvidersOfWorker,
  updateInductionStatus: updateInductionSlice,
  updateRoute: updateRoute,
  InductionModuleData: inductionModuleDataSlice,
  deletedocument: deletedocument,
  updateAvtar: updateAvtar,
  closeDocument: closeDocument,
  getAllworkerDocType: getAllworkerDocType,
  refetchAllInductions: refetchAllInductions,
  siteInductionDetailData: siteInductionDetailSlice,
  GetHighRiskData: getHighRiskSlice,
  AllContactsOfContractor: GetAllContactsOfProvider,
  AllClientsOfAdminProvider: GetAllClientsOfAdminProvider,

  AllContactsOfContractorPortal: GetAllContactsOfContractorPortal,
  editInduction: editInductionSlice,
  SpecificContractorWorkerInvites: GetProviderWorkersInvite,
  GetAdminSpecificContractorData: GetAdminSpecificContractor,
  GetAdminSpecificWorkerData: GetAdminSpecificWorker,
  DocumentHistoryData: GetDocumentHistoryById,
  ContractorDocumentHistoryData: GetContractorDocumentHistoryById,
  GetContractorDocData: GetContractorDoc,
  WorkerDocumentHistoryData: GetWorkerDocumentHistoryById,
  inductionDetailData: inductionDetailSlic,
  updateDefaultTabe: updateDefaultTabeSlice,
  getAllComplianceData: getAllComplianceSlice,
  GetAllInvitedContractorsOfClientData: slice,
  GetAllDocumentTypeList: getAllDoctype,
  GetAllContractorDocumentTypeList: getAllContractorDoctype,
  CSVData: clientCsv,
  inviteContractorWorkerCsvData: inviteWorkerCsvView,
  inviteProviderCsvData: inviteProviderCsvView,
  AllContactTypes: createSlice,
  Auth: AuthReducer,
  Admin: AdminReducer,
  Client: ClientReducer,
  functionAssignmentUuid: functionAssignmentUuidReducer,
  allFunctions: AllFunctionReducer,
  allRole: AllRoleReducer,
  breadcrumb: breadcrumbsSlice,
  editSiteInduction: editSiteInductionSlice,
  SiteInductionModuleData: SiteInductionModuleDataSlice,
  closeDocumentSite: closeDocumentSite,
  deleteDocumentSite: deleteDocumentSite,
  refetchAllInductionsSite: refetchAllInductionsSite,
  updateInductionStatusSite: updatInductionStatusSite,
  specificClientDetails: specificClientDetails,
  // Provider Registration Code
  ProviderRegisterData:ProviderRegisterData,
  RegistrationValidation:RegistrationValidation,
  ProviderContactData:ProviderContactData,
  checkExistUser:checkExistUser,
  SignUpData:SignUp,
  specificWorker:specificWorker,
  GetSpecificWorkerIndData:GetSpecificWorkerIndSlice,
  ProviderWorkerSpecificData:ProviderWorkerSpecificData,
  SpecificProviderData:SpecificProviderData
  // CompanyInductionModule: CompanyInductionModule
});
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
// Initialize Axios Request / Response Interceptors etc
AxiosConfig();

const token = "A-9BU9nMQNacRoHebPle7okDAci";
// const token = "1|8|IjXmeq4nrQRoPlmr84Tm7x2dDZUdpW08fYuuoyRQpIef3AHcFB";

const routing = (
  <UserbackProvider token={token} options={{ autohide: false }}>
    {/* <GleapLoader> */}
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    {/* </GleapLoader> */}
  </UserbackProvider>
);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(routing);
export default store;
export const persistor = persistStore(store);
